export const storageKeys = {
    TOKEN: "accessToken",
    SUPERADMIN_TOKEN: "superAdminToken",
    USER_ID: "userId",
    USER_CITY: "userCity",
    USER_LAT: "userLat",
    USER_LONG: "userLong",
    USER_ROLE: "userRole",
    USER_COUNTRY: "userCountry",
    COMPANY_ID: "companyId",
    CURRENCY: "currency",
    LANGUAGE: "language",
    USER_EMAIL: "email",
    EMAIL: "email",
    COMPANY_VAT: "vat",
    LOGO: "logo",
    COMPANY_NAME: "companyName",
    COMPANY_PARENT_NAME: "companyParentName",
    APPLY_TAX: "applicableTax",
    PAYMENT_METHODS: "paymentMethods",
    HOTELS_FEE: "hotelsFee",
    ACTIVITIES_FEE: "activitiesFee",
    TRANSFERS_FEE: "transfersFee",
    CARS_FEE: "carsFee",
    FLIGTHS_DOMESTIC_FEE: "flightsDomesticFee",
    FLIGTHS_INTERNATIONAL_FEE: "flightsInternationalFee",
    PACKAGE_FEE: "packagesFee",
    CRUISES_FEE: "cruisesFee",
    USER: "user",
    USER_NAME: "userName",
    USER_FIRST_NAME: "firstName",
    USER_LAST_NAME: "lastName",
    SUBSCRIPTION_PLAN: "subscriptionPlan",
    BANK_NAME: "bankName",
    BANK_ACCOUNT: "bankAccount",
    SWIFT: "swift",
    BANK_ACCOUNT_OWNER: "bankAccountOwner",
    ONBOARDING_STATUS: "onboardingStatus",
    COMPANY_EMAIL: "companyEmail",
    KEEP_SESSION_ACTIVE: "keepSessionActive",
    SHOW_CUSTOME_REDIRECT_PROJECTS:"showCustomRedirectAdviceProjects",
    HAS_LOYALTY_CARDS: "hasLoyaltyCards",
    SHOW_STARS_MESSAGE: "showStarsMessage",
};

export const setItem = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key) => {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
};

export const removeItem = (key) => {
    sessionStorage.removeItem(key);
};

export const clearSessionStorage = () => {
    sessionStorage.clear();
};
