import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axiosInstance from "src/config/axiosInstance";
import StripeContext from "./StripeContext";
import { SplashScreen } from "../loading-screen";

export const StripeProvider = ({ children }) => {
    const [stripeApiKey, setStripeApiKey] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        const fetchStripeApiKey = async () => {
            try {
                const { data } = await axiosInstance.get(
                    "/integrations/stripe",
                );

                setStripeApiKey(data.apiKey);
                setStripePromise(loadStripe(data.apiKey));
            } catch (error) {
                console.error("Error fetching Stripe API Key", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchStripeApiKey();
    }, []);

    return (
        <StripeContext.Provider value={{ stripeApiKey, stripePromise }}>
            {isLoading || !stripePromise ? (
                <SplashScreen />
            ) : (
                <Elements stripe={stripePromise}>{children}</Elements>
            )}
        </StripeContext.Provider>
    );
};

export default StripeProvider;
