import { paths } from "src/routes/paths";
import { useLocales } from "src/locales";
import { useMemo } from "react";
import { useAuthContext } from "src/auth/hooks";
import { ICONS } from "src/constants";
import { baseAdminItems, approverItems, superAdminItems } from "src/constants";

export function useNavData() {
    const { t } = useLocales();
    const { user } = useAuthContext();

    const role = useMemo(() => user?.role || user?.userRole, [user]);

    const showOptions = useMemo(
        () =>
            role === "superadmin" ||
            // (user?.company?.isParent && role === "business_admin") ||
            role === "business_admin" ||
            role === "approver",
        [role, user],
    );

    const itemsValidated = useMemo(() => {
        if (role === "approver" && showOptions) return approverItems;

        if (role === "business_admin" && showOptions)
            return approverItems.concat(baseAdminItems);

        if (role === "superadmin" && showOptions)
            return [...approverItems, ...baseAdminItems, ...superAdminItems];
    }, [role, showOptions]);

    const data = useMemo(
        () => [
            {
                items: [
                    {
                        title: t("Home"),
                        path: paths.dashboard.home.root,
                        icon: ICONS.label,
                    },
                ],
            },

            // Main Menu
            // ----------------------------------------------------------------------
            {
                items: [
                    // // MAIL

                    // PROJECT
                    {
                        title: (
                            <strong style={{ color: "black", fontSize: 14 }}>
                                {t("Smart Trips")}
                            </strong>
                        ),
                        path: paths.dashboard.project.list,
                        icon: ICONS.dashboard,
                    },
                    {
                        title: (
                            <strong style={{ color: "black" }}>
                                {t("Flights")}
                            </strong>
                        ),
                        path: paths.dashboard.flight.home,
                        icon: ICONS.flight,
                    },
                    {
                        title: (
                            <strong style={{ color: "black" }}>
                                {t("Hotels")}
                            </strong>
                        ),
                        path: paths.dashboard.hotel.list,
                        icon: ICONS.hotel,
                    },
                    // {
                    //     title: (
                    //         <strong style={{ color: "black" }}>
                    //             {t("Transfers")}
                    //         </strong>
                    //     ),
                    //     path: paths.dashboard.transfer.home,
                    //     icon: ICONS.transfer,
                    // },
                ],
            },
            showOptions && {
                subheader:
                    role === "superadmin" ? t("admin") : t("travelandz admin"),
                items: itemsValidated,
            },
        ],
        [t],
    );

    return data;
}
