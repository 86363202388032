import { Suspense } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import MainLayout from "src/layouts/main";
import { AuthConsumer, AuthProvider } from "src/auth/context/jwt";
import { ExchangeRateProvider } from "src/exchange-rate";
import { CheckoutProvider } from "src/sections/hotelCheckout/context";
import { TransferProvider } from "src/sections/transferCheckout/context";
import { FlightProvider } from "src/sections/flightCheckout/context";
import { HomePage, mainRoutes } from "./main";
import { authDemoRoutes } from "./auth-demo";
import { authRoutes } from "./auth";
import { dashboardRoutes } from "./dashboard";

export default function Router() {
    return useRoutes([
        {
            path: "/",
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <MainLayout>
                        <HomePage />
                    </MainLayout>
                </Suspense>
            ),
        },
        ...mainRoutes,
        {
            element: (
                <AuthProvider>
                    <ExchangeRateProvider>
                        <CheckoutProvider>
                            <TransferProvider>
                                <FlightProvider>
                                    <AuthConsumer>
                                        <Outlet />
                                    </AuthConsumer>
                                </FlightProvider>
                            </TransferProvider>
                        </CheckoutProvider>
                    </ExchangeRateProvider>
                </AuthProvider>
            ),
            children: [...authRoutes, ...authDemoRoutes, ...dashboardRoutes],
        },
        { path: "*", element: <Navigate to="/404" replace /> },
    ]);
}
