import axiosInstance from "src/config/axiosInstance";

class CrossProjectService {
    static async createCrossProject(data) {
        try {
            const response = await axiosInstance.post(
                "/crossprojects/createCrossProject",
                data,
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async validateField(input, type) {
        try {
            const response = await axiosInstance.get(
                `/crossprojects/validate/field?query=${input}&type=${type}`,
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async getCrossProjects(companyId) {
        try {
            const response = await axiosInstance.get(
                `/crossprojects/${companyId}`,
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async UpdateCrossProject(data, crossProjectId) {
        try {
            const response = await axiosInstance.put(
                `/crossprojects/UpdatedCrossProject/${crossProjectId}`,
                data,
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async getCrossProjectEmployees(crossProjectId) {
        try {
            const response = await axiosInstance.get(
                `/crossprojects/employees/${crossProjectId}`,
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async UpdateCrossProjectEmployees(data, crossProjectId) {
        try {
            const response = await axiosInstance.patch(
                `/crossprojects/updateEmployees/${crossProjectId}`,
                data,
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async getCrossProjectsForEmployee(userId) {
        try {
            const response = await axiosInstance.get(
                `/crossprojects/employee/projects/${userId}`,
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async getCrossProjectByName(keyword) {
        const res = await axiosInstance.get("/crossprojects/byKeyword", {
            params: { keyword },
        });
        return res.data?.crossProjects ?? [];
    }
}

export default CrossProjectService;
