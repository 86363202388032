import { axiosInstance } from "src/config/axiosInstance";

class ProjectsService {
    static #specialKey = process.env.REACT_APP_SPECIAL_KEY;

    static async updateProjectTerms(projectId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/projects/updateTerms/${projectId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the Itinerary:",
                error,
            );
            throw error;
        }
    }

    static async fetchProjectsList(
        companyId,
        maxRetries,
        retryDelay,
        timeout = 7000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/company/${companyId}`,
                    {
                        timeout: timeout,
                    },
                );
                return response;
            } catch (error) {
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchUserProjects(userId, maxRetries, retryDelay) {
        console.log("fetching doe userId", userId);
        try {
            const response = await axiosInstance.get(
                `/projects/employee/${userId}`,
            ); // Assuming user-specific endpoint
            return response;
        } catch (error) {
            console.error("Failed to fetch user projects:", error);
            throw error;
        }
    }

    static async fetchApproverProjects(userId) {
        try {
            const response = await axiosInstance.get(
                `/projects/approver/${userId}`,
            ); // Assuming approver-specific endpoint
            return response;
        } catch (error) {
            console.error("Failed to fetch approver projects:", error);
            throw error;
        }
    }

    static async deleteProjectById(projectId) {
        try {
            const response = await axiosInstance.post(
                `/projects/delete/${projectId}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async deleteProjects(emailsIds) {
        try {
            const response = await axiosInstance.delete(
                `/projects/delete`,
                emailsIds,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async archiveProjects(emailsIds) {
        try {
            const response = await axiosInstance.delete(
                `/projects/delete`,
                emailsIds,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async fetchProjectDetails(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/${projectId}`,
                    {
                        headers: {
                            "tz-api-key": process.env.REACT_APP_SPECIAL_KEY,
                        },
                    },
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async fetchProjectDetailsAndItinerary(
        projectId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/detailsAndItinerary/${projectId}`,
                    {
                        timeout: 7000,
                    },
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
                throw error;
            }
        }
    }

    static async fetchProjectDetailsByLast8Digits(
        last8Digits,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                console.log(
                    "Fetching project details by last 8 digits:",
                    last8Digits,
                );
                const response = await axiosInstance.get(
                    `/projects/notifications/${last8Digits}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createProject(data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/projects/new`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the project data is in the response's data property
            } catch (error) {
                console.error("Error creating project:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        // Return null or an appropriate value if the retries are exhausted
        return null;
    }

    static async fetchWorkUser(userId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/projects/user/${userId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async fetchRecentActivity(userId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/projects/recentActivity/${userId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchProjectsSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/summary/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }

        // Después de los intentos, lanzar una excepción indicando el fallo
        throw new Error(
            `Failed to fetch project details after ${maxRetries} retries`,
        );
    }

    static async fetchProjectsSummaryVat(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/summary/parents/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }

        // Después de los intentos, lanzar una excepción indicando el fallo
        throw new Error(
            `Failed to fetch project details after ${maxRetries} retries`,
        );
    }

    static async fetchDataDashboard(companyId, data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const url = `/projects/dashboard/${companyId}`;

                // Verificar si se proporcionan fechas y agregarlas a la URL si es así
                if (data) {
                    const response = await axiosInstance.post(
                        `/projects/dashboard/${companyId}`,
                        data,
                    );
                    return response;
                } else {
                    return await axiosInstance.get(url);
                }
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }

        // Si llegamos a este punto, lanzar una excepción para indicar el fallo
        throw new Error(
            `Failed to fetch project details after ${maxRetries} retries`,
        );
    }

    static async fetchProjectPreferences(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/preferences/${projectId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async saveFeedback(data) {
        try {
            console.log("data", data);
            const response = await axiosInstance.post(`/feedback/new`, data);
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async saveProviderFeedback(data) {
        try {
            console.log("data", data);
            const response = await axiosInstance.post(
                `/feedback/provider/new`,
                data,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async saveItemFeedback(data) {
        try {
            const response = await axiosInstance.post(
                `/feedback/item/new`,
                data,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async addNextPayment(projectId, data) {
        return axiosInstance.post(
            `/projects/addNextPayment/${projectId}`,
            data,
        );
    }
    static async askForPayment(projectId, data) {
        return axiosInstance.post(`/projects/askForPayment/${projectId}`, data);
    }

    static async convertProjectInATour(projectId, data) {
        try {
            const response = await axiosInstance.post(
                `/projects/createTourFromProject/${projectId}`,
                data,
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while converting the project into a tour:",
                error,
            );
            throw error;
        }
    }

    static async CalculateProjectPrice(projectId, body) {
        try {
            const response = await axiosInstance.put(
                `/projects/calculateProjectPrice/${projectId}`,
                body,
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while calculating the project price:",
                error,
            );
            return { error: true };
        }
    }

    static async checkOffersFlights(projectId) {
        try {
            const response = await axiosInstance.put(
                `/projects/udapteFligthsOffers/${projectId}`,
            );

            return response?.data;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async updateProjectGrandTotal(projectId, total) {
        try {
            const response = await axiosInstance.put(
                `/projects/updateGrandTotal/${projectId}`,
                { total },
            );
            return response.data;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async updateBackgroundImage(projectId, imageUrl) {
        try {
            const response = await axiosInstance.put(
                `/projects/updateBackgroundImage/${projectId}`,
                { imageUrl },
            );

            return response.data;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async updatePhotoPlaceImage(projectId, imageUrl) {
        try {
            const response = await axiosInstance.put(
                `/projects/changeProjectImage/${projectId}`,
                { imageUrl },
            );

            return response.data;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async getEmployeeProjects(userId) {
        try {
            const response = await axiosInstance.get(
                `/projects/employee/${userId}`,
            );

            return response.data;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async updateBookingProjectFlights(
        projectId,
        flightBookingId,
        verify = false,
        maxRetries = 3,
        retryDelay = 500,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.put(
                    `/projects/updateBookingsFlights/${projectId}?flightBookingId=${flightBookingId}&verify=${verify}`,
                );

                return response.data;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getRelatedProjects(
        checkIn,
        checkOut,
        departureCity,
        arrivalCity,
        viewType,
    ) {
        try {
            const response = await axiosInstance.post(
                "/projects/relatedProjects",
                {
                    checkIn,
                    checkOut,
                    departureCity,
                    arrivalCity,
                    viewType,
                },
            );

            return response?.data?.projects;
        } catch (error) {
            throw error;
        }
    }

    static async getUpcomingProjects() {
        try {
            const response = await axiosInstance.get(
                "/projects/upcoming/projects",
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async updateItineraryProjectFlightOffer(projectId, flightOffer) {
        try {
            const response = await axiosInstance.put(
                `/projects/update/itineraryFlightOffer/${projectId}`,
                {
                    flight: flightOffer,
                },
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async UpdateCrossProjectId(projectId, crossProjectId) {
        try {
            const response = await axiosInstance.patch(
                `/projects/updateCrossProject/${projectId}`,
                {
                    crossProjectId,
                },
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }
    static async fetchProjectByKeyword(keyword, crossProject) {
        const res = await axiosInstance.get("/projects/byKeyword", {
            params: { keyword, crossProject },
        });
        return res.data?.projects ?? [];
    }
}

export default ProjectsService;
