const BASE_STEP_VALUES=[
    // {
    //     step:0,
    //     label:"Select an existing trip"
    // },
]

const FLIGHT_STEP_VALUES=[
    // BASE_STEP_VALUES[0],
    {
        step:2,
        label:"Confirm passenger details"
    },
]

const BOOK_FOR_STEP={
    step:1,
    label:"Projects"
}

export {
    BASE_STEP_VALUES,
    FLIGHT_STEP_VALUES,
    BOOK_FOR_STEP
}