import { paths } from "src/routes/paths";
import { ICONS } from "../";

const baseAdminItems = [
    {
        title: "Business Admin",
        path: paths.dashboard.admin.agencies,
        icon: ICONS.folder,
        children: [
            {
                title: "Companies",
                path: paths.dashboard.admin.agencies,
                icon: ICONS.companies,
            },
            {
                title: "Business Units",
                path: paths.dashboard.department.list,
                icon: ICONS.business,
            },
            {
                title: "Employees",
                path: paths.dashboard.user.list,
                icon: ICONS.employees,
            },
            {
                title: "Projects",
                path: paths.dashboard.crossproject.root,
                icon: ICONS.crossprojects,
            },
            {
                title: "Travel Policies",
                path: paths.dashboard.travelPolicy.list,
                icon: ICONS.travelPolicies,
            },
            {
                title: "Spending Limits",
                path: paths.dashboard.spendingLimits.list,
                icon: ICONS.spendingLimits,
            },
            {
                title: "Reports",
                path: paths.dashboard.reports.root,
                icon: ICONS.reports,
            },
        ],
    },
];

const approverItems = [
    {
        title: "Approvals",
        path: paths.dashboard.approval.list,
        icon: ICONS.approvals,
    },
    {
        title: "Expenses",
        path: paths.dashboard.expense.list,
        icon: ICONS.booking,
        children: [
            {
                title: "List",
                path: paths.dashboard.expense.list,
                icon: ICONS.list,
            },
            {
                title: "Reports",
                path: paths.dashboard.expense.report,
                icon: ICONS.reports,
            },
            {
                title: "Link your bank",
                path: paths.dashboard.user.linkBank,
                icon: ICONS.bank,
            },
        ],
    },
];

const superAdminItems = [
    {
        title: "Super Admin",
        path: paths.dashboard.admin.salesreport,
        icon: ICONS.folder,
        children: [
            {
                title: "Companies",
                path: paths.dashboard.admin.agencies,
                icon: ICONS.companies,
            },
            {
                title: "Business Units",
                path: paths.dashboard.admin.departments,
                icon: ICONS.business,
            },
            {
                title: "Travel Policies",
                path: paths.dashboard.admin.travelPolicies,
                icon: ICONS.travelPolicies,
            },
            {
                title: "Bookings",
                path: paths.dashboard.admin.bookings,
                icon: ICONS.booking,
            },
            {
                title: "Trips",
                path: paths.dashboard.admin.trips,
                icon: ICONS.booking,
            },
            {
                title: "Reports",
                path: paths.dashboard.admin.salesreport,
                icon: ICONS.reports,
                children: [
                    {
                        title: "Sales",
                        path: paths.dashboard.admin.salesreport,
                        icon: ICONS.sales,
                    },
                    {
                        title: "Businesses",
                        path: paths.dashboard.admin.clientsreport,
                        icon: ICONS.business,
                    },
                ],
            },
        ],
    },
];

export { baseAdminItems, approverItems, superAdminItems };
