import "src/locales/i18n";
import "simplebar-react/dist/simplebar.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "highlight.js/styles/default.css";
import "react-quill/dist/quill.snow.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "src/assets/css/main.css";

import { SettingsDrawer, SettingsProvider } from "src/components/settings";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LicenseInfo } from "@mui/x-license-pro";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MotionLazy from "src/components/animate/motion-lazy";
import ProgressBar from "src/components/progress-bar";
import Router from "src/routes/sections";
import SnackbarProvider from "src/components/snackbar/snackbar-provider";
import ThemeProvider from "src/theme";
import es from "date-fns/locale/es";
import { useScrollToTop } from "src/hooks/use-scroll-to-top";
import { Provider as ReduxProvider } from "react-redux";
import { ReduxStore } from "./store/store";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./tanstack/QueryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export default function App() {
    useScrollToTop();
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <SettingsProvider
                defaultSettings={{
                    themeMode: "light",
                    themeDirection: "ltr",
                    themeContrast: "default",
                    themeLayout: "horizontal",
                    themeColorPresets: "blue",
                    themeStretch: false,
                }}
            >
                <ReduxProvider store={ReduxStore}>
                    <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools initialIsOpen={false} />
                        <ThemeProvider>
                            <MotionLazy>
                                <SnackbarProvider>
                                    <SettingsDrawer />
                                    <ProgressBar />
                                    <Router />
                                </SnackbarProvider>
                            </MotionLazy>
                        </ThemeProvider>
                    </QueryClientProvider>
                </ReduxProvider>
            </SettingsProvider>
        </LocalizationProvider>
    );
}
