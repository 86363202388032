import { FlightsService } from "src/api/tz";
import { getAirportsList } from "./getAirportList";

export const parseFlightData = async (tripData, roundTrip) => {
    const { departureCity, returnCity, cabinClass, destinations } = tripData;

    if (!Array.isArray(destinations)) {
        return [];
    }
    const destinationsWithDeparture = destinations.map((d) => ({
        name: d.city?.name || d.name,
        country: d.city?.country || d.country,
        startDate: d.startDate,
        endDate: d.endDate,
    }));
    if (departureCity) {
        destinationsWithDeparture.unshift({
            name: departureCity.city || departureCity.name,
            country: departureCity.country,
        });
    }
    if (returnCity) {
        destinationsWithDeparture.push({
            name: returnCity.city || returnCity.name,
            country: returnCity.country,
        });
    }

    const cities = await Promise.all(
        destinationsWithDeparture.map(async (destination) => {
            const destinationName = destination?.name;
            const destinationCountry = destination?.country;

            const response = await getAirportsList(destinationName);
            const exactMatch = getExactMatch(
                response,
                destinationName,
                destinationCountry,
            );
            if (exactMatch) {
                return {
                    name: destinationName,
                    code: exactMatch.code,
                };
            }
            const body = {
                radius: 60,
                queryString: destination?.name,
                lat: destination?.latitude,
                lng: destination?.longitude,
            };
            const airportsResponse = await FlightsService.getNearestAirports(
                body,
                3,
                1000,
            );

            return {
                name: destinationName,
                code: airportsResponse?.airports?.[0]?.iata_code,
            };
        }),
    );
    const slices = [];
    slices.push({
        origin: cities[0].code,
        destination: cities[1].code,
        departure_date: new Date(destinations[0].startDate)
            .toISOString()
            .split("T")[0],
        itineraryCity: cities?.[0]?.name?.toLowerCase() || "",
        itineraryDestinationCity: cities?.[1]?.name?.toLowerCase() || "",
        type: "departure",
    });
    for (let i = 1; i < cities.length - 1; i++) {
        slices.push({
            origin: cities[i].code,
            destination: cities[i + 1].code,
            departure_date: new Date(destinations[i].startDate)
                .toISOString()
                .split("T")[0],
            itineraryCity: cities?.[i]?.name?.toLowerCase(),
            itineraryDestinationCity: cities?.[i + 1]?.name?.toLowerCase(),
            type: "departure",
        });
    }

    if (roundTrip) {
        slices.push({
            origin: cities[cities.length - 1].code,
            destination: cities[0].code,
            departure_date: new Date(
                destinations[destinations.length - 1].endDate,
            )
                .toISOString()
                .split("T")[0],
            itineraryCity:
                cities?.[cities.length - 1]?.name?.toLowerCase() || "",
            type: "arrival",
        });
    }

    const passengers = [];
    passengers.push({
        type: "adult",
    });

    return {
        slices,
        passengers,
        cabin_class: cabinClass,
        currency: "EUR",
    };
};

export const parseFlightSlices = (tripData) => {
    const { destinations, airports, departureCity, returnCity, cabinClass } =
        tripData;

    const slices = [
        {
            origin: departureCity.code,
            destination: airports[0].code,
            departure_date: new Date(destinations[0].startDate)
                .toISOString()
                .split("T")[0],
            itineraryCity: destinations[0].city.name?.toLowerCase() || "",
            itineraryDestinationCity: destinations[0].city.name?.toLowerCase() || "",
            type: "departure",
        },
    ];

    for (let i = 0; i < destinations.length - 1; i++) {
        slices.push({
            origin: airports[i].code,
            destination: airports[i + 1].code,
            departure_date: new Date(destinations[i+1].startDate)
                .toISOString()
                .split("T")[0],
            itineraryCity: destinations[i].city.name?.toLowerCase(),
            itineraryDestinationCity: destinations[i + 1].city.name?.toLowerCase(),
            type: "departure",
        });
    }

    slices.push({
        origin: airports[airports.length - 1].code,
        destination: returnCity.code,
        departure_date: new Date(destinations[destinations.length - 1].endDate)
            .toISOString()
            .split("T")[0],
        itineraryCity:
            destinations[destinations.length - 1].city.name?.toLowerCase() ||
            "",
        itineraryDestinationCity: destinations[destinations.length - 1].city.name?.toLowerCase() || "",
        type: "arrival",
    });

    const passengers = [];
    passengers.push({
        type: "adult",
    });

    return {
        slices,
        passengers,
        cabin_class: cabinClass,
        currency: "EUR",
    };
};
const getExactMatch = (response, destinationName, destinationCountry) => {
    return (
        response.find((d) => {
            if (destinationCountry && d.country_name !== destinationCountry)
                return false;
            return d.type === "airport" && d.city_name === destinationName;
        }) ||
        response.find((d) => {
            if (destinationCountry && d.country_name !== destinationCountry)
                return false;
            return d.type === "city" && d.name === destinationName;
        })
    );
};
