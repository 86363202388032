import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
//
import App from "./App";

// ----------------------------------------------------------------------
//test to see if the service worker makes the error
//serviceWorkerRegistration.register();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <HelmetProvider>
        <BrowserRouter>
            <Suspense>
                <App />
            </Suspense>
        </BrowserRouter>
    </HelmetProvider>,
);
