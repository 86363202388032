import {
    AccountPopover,
    LanguagePopover,
    NotificationsPopover,
} from "../_common";
import { HEADER, NAV } from "../config-layout";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Logo from "src/components/logo";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import SvgColor from "src/components/svg-color";
import Toolbar from "@mui/material/Toolbar";
import { bgBlur } from "src/theme/css";
import { useOffSetTop } from "src/hooks/use-off-set-top";
import { useResponsive } from "src/hooks/use-responsive";
import { useSettingsContext } from "src/components/settings";
import { useTheme } from "@mui/material/styles";
import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hooks";
import { useLocales } from "src/locales";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function Header({ onOpenNav }) {
    const theme = useTheme();
    const settings = useSettingsContext();
    const isNavHorizontal = settings.themeLayout === "horizontal";
    const isNavMini = settings.themeLayout === "mini";
    const lgUp = useResponsive("up", "lg");
    const offset = useOffSetTop(HEADER.H_DESKTOP);
    const offsetTop = offset && !isNavHorizontal;

    const renderContent = (
        <>
            {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

            {!lgUp && (
                <IconButton onClick={onOpenNav}>
                    <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
                </IconButton>
            )}

            <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1 }}
            >
                <LanguagePopover />
                {/*<Typography
                    variant="body2"
                    sx={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        px: 2,
                        py: 0.5,
                        borderRadius: 1,
                    }}
                >
                    Support 24/7:{" "}
                    <a
                        href="tel:+34655535155"
                        style={{
                            marginLeft: 5,
                            color: "#00BFFF",
                            textDecoration: "none",
                        }}
                    >
                        Chat with us
                    </a>
                </Typography>*/}
                <AccountPopover />
            </Stack>
        </>
    );

    return (
        <AppBar
            sx={{
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(["height"], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(lgUp && {
                    width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
                    height: HEADER.H_DESKTOP,
                    ...(offsetTop && {
                        height: HEADER.H_DESKTOP_OFFSET,
                    }),
                    ...(isNavHorizontal && {
                        width: 1,
                        bgcolor: "background.default",
                        height: HEADER.H_DESKTOP_OFFSET,
                        borderBottom: `dashed 1px ${theme.palette.divider}`,
                    }),
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_MINI + 1}px)`,
                    }),
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 },
                    backgroundColor: "#1a1a33",
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    onOpenNav: PropTypes.func,
};
