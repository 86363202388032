import React from "react";
import logo from "./assets/logo.svg";
import fa from "./assets/face.png";
import ins from "./assets/Instagram.svg";
import link from "./assets/link.png";
import tw from "./assets/tw.svg";
const Footer = () => {
    return (
        <div className=" py-[60px] mt-[30px] md:mt-[180px] bg-[#ECECED]">
            <div className="container flex flex-col items-center justify-center">
                <img src={logo} alt="" />

                <p className="mt-[40px] text-[#808080] ">
                    © 2025 made with love by Travelandz | All rights reserved
                </p>
            </div>
        </div>
    );
};

export default Footer;
