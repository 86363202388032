// import { currencies } from "src/assets/data";

export const MIN_MIXED_AMOUNT = {
    EUR: 10,
    USD: 10,
    INR: 1000,
    CHF: 10,
    JPY: 1500,
    GBP: 10,
    AUD: 15,
    CAD: 10,
    CNY: 70,
    SGD: 10,
    NZD: 15,
    MXN: 200,
};
