import SvgColor from "src/components/svg-color";
import Iconify from "src/components/iconify";

const icon = (name) => (
    <SvgColor
        src={`/assets/icons/navbar/${name}.svg`}
        sx={{ width: 1, height: 1 }}
    />
);
const iconifyIcon = (name) => <Iconify icon={name} width={1} />;

export const ICONS = {
    home: icon("ic_home"),
    aipilot: icon("ic_pilot"),
    job: icon("ic_job"),
    blog: icon("ic_blog"),
    chat: icon("ic_chat"),
    mail: icon("ic_mail"),
    activity: icon("ic_activity"),
    accounts: icon("ic_accounts"),
    campaign: icon("ic_campaign"),
    user: icon("ic_user"),
    file: icon("ic_file"),
    lock: icon("ic_lock"),
    tour: icon("ic_tour"),
    ourproducts: icon("ic_ourproducts"),
    flight: icon("ic_flight"),
    transfer: icon("ic_transfer"),
    carRental: icon("ic_car_rental"),
    order: icon("ic_order"),
    label: icon("ic_label"),
    blank: icon("ic_blank"),
    kanban: icon("ic_kanban"),
    folder: icon("ic_folder"),
    banking: icon("ic_banking"),
    customer: icon("ic_customer"),
    lead: icon("ic_lead"),
    hotel: icon("ic_hotel"),
    booking: icon("ic_booking"),
    invoice: icon("ic_invoice"),
    quotation: icon("ic_quotation"),
    product: icon("ic_product"),
    calendar: icon("ic_calendar"),
    disabled: icon("ic_disabled"),
    external: icon("ic_external"),
    providers: icon("ic_providers"),
    menuItem: icon("ic_menu_item"),
    ecommerce: icon("ic_ecommerce"),
    analytics: icon("ic_analytics"),
    projects: icon("ic_project"),
    dashboard: icon("ic_dashboard"),
    incomes: icon("ic_incomes"),
    expenses: icon("ic_expenses"),
    ledger: icon("ic_ledger"),
    tasks: icon("ic_tasks"),
    promotions: icon("ic_promotions"),
    approvals: iconifyIcon("material-symbols-light:order-approve-outline"),
    companies: iconifyIcon("ion:business-outline"),
    business: iconifyIcon("clarity:organization-line"),
    employees: iconifyIcon("la:user-tie"),
    travelPolicies: iconifyIcon("fluent:document-ribbon-48-regular"),
    spendingLimits: iconifyIcon("solar:hand-money-broken"),
    reports: iconifyIcon("system-uicons:document"),
    list: iconifyIcon("tdesign:list"),
    sales: iconifyIcon("lsicon:deal-filled"),
    crossprojects: iconifyIcon("la:project-diagram"),
    bank: iconifyIcon("mingcute:bank-line"),
};
