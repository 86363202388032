import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import axiosInstance from "src/config/axiosInstance";
import { useSnackbar } from "src/components/snackbar";
import { useLocales } from "src/locales";
import axios from "axios";

export const GoogleMapsPlacesContext = createContext({});

const translateToEnglish = async (localizedPlace, apiKey) => {
    console.log("Localized Place:", localizedPlace);

    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        localizedPlace,
    )}&key=${apiKey}&language=en`;

    try {
        const response = await axios.get(url);
        console.log("Translation Response:", response.data);
        const result = response.data.results[0];

        if (!result) {
            throw new Error("No results found for the given place.");
        }

        // Get the formatted address in English
        const formattedAddress = result.formatted_address || localizedPlace;

        // Extract country code from address_components
        const countryComponent = result.address_components.find((component) =>
            component.types.includes("country"),
        );
        const countryCode = countryComponent
            ? countryComponent.short_name
            : null;

        // Extract city/locality
        const cityComponent = result.address_components.find((component) =>
            component.types.includes("locality"),
        );
        const city = cityComponent ? cityComponent.long_name : null;

        return {
            formattedAddress, // Full address in English
            city, // City name in English
            countryCode, // ISO country code
        };
    } catch (error) {
        console.error("Error translating to English:", error);

        // Return a consistent fallback object
        return {
            formattedAddress: localizedPlace,
            city: null,
            countryCode: null,
        };
    }
};

export function GoogleMapsPlacesProvider({ children }) {
    const [apiKey, setApiKey] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const isFetching = useRef(false);
    const { enqueueSnackbar } = useSnackbar();
    const { currentLang } = useLocales();
    useEffect(() => {
        if (isFetching.current) return;
        const fetchMapsAPI_KEY = async () => {
            isFetching.current = true;
            try {
                const { data } = await axiosInstance.get(
                    "/integrations/google-places",
                );
                setApiKey(data.apiKey);
            } catch (error) {
                enqueueSnackbar("Error fetching Google Maps API Key", {
                    variant: "error",
                });
            }
            isFetching.current = false;
        };
        // get API
        fetchMapsAPI_KEY();
    }, []);
    const fetchPlaceSuggestions = useCallback(
        async (input) => {
            if (!window.google)
                throw new Error("Google Maps Script not loaded");
            const autocompleteService =
                new window.google.maps.places.AutocompleteService();
            const result = await autocompleteService.getPlacePredictions({
                input,
                types: ["geocode"],
                language: currentLang.value,
            });
            if (!result) throw new Error("Failed to fetch place suggestions");
            return result.predictions;
        },
        [currentLang],
    );

    const getPlaceById = useCallback(
        async (placeId, label) => {
            if (!window.google)
                throw new Error("Google Maps Script not loaded");
            const geocoder = new window.google.maps.Geocoder();

            try {
                const res = await geocoder.geocode({
                    placeId,
                    language: "en-US",
                });

                if (!res || !res.results || res.results.length === 0) {
                    throw new Error("Failed to fetch place details");
                }

                const results = res.results;
                const location = results[0].geometry.location;

                let city = "";
                let country = "";
                let countryCode = "";

                // Loop through results to find city (locality) and country
                results[0].address_components.forEach((component) => {
                    if (
                        component.types.includes("locality") ||
                        component.types.includes("postal_town")
                    ) {
                        city = component.long_name; // Locality (city) or similar
                    }
                    if (component.types.includes("country")) {
                        country = component.long_name; // Full country name
                        countryCode = component.short_name; // ISO country code
                    }
                });
                if (!city) {
                    city = results[0].formatted_address.split(",")[0];
                }
                console.log("City:", city, "Country:", country);
                // If no city found and it's an address (e.g., street_number or route), we might need to fallback
                const isAddress = results[0].address_components.some(
                    (c) =>
                        c.types.includes("street_number") ||
                        c.types.includes("route"),
                );

                // Fallback to label for city if geocoding doesn't return a locality
                if (!city && label) {
                    city = label.split(", ")[0]; // Fallback to first part of label (before the comma)
                }

                // Ensure English names by checking a fallback translation
                let translatedCity = city;
                let translatedCountry = country;

                if (
                    !city ||
                    !country ||
                    city !== city.toLowerCase() ||
                    country !== country.toLowerCase()
                ) {
                    try {
                        const { formattedAddress } = await translateToEnglish(
                            `${city}, ${country}`,
                            apiKey,
                        );
                        [translatedCity, translatedCountry] = formattedAddress
                            .split(", ")
                            .map((item) => item.trim());
                    } catch (translationError) {
                        console.warn(
                            "Translation failed, falling back to original values",
                            translationError,
                        );
                    }
                }

                console.log(results[0]);

                return {
                    city: translatedCity,
                    country: translatedCountry,
                    countryCode, // Include country code
                    latitude: location.lat(),
                    longitude: location.lng(),
                    isAddress,
                    adressName: results?.[0]?.address_components?.[0]?.long_name,
                };
            } catch (error) {
                console.error("Error in getPlaceById:", error);
                throw error;
            }
        },
        [apiKey],
    );

    return (
        <GoogleMapsPlacesContext.Provider
            value={{ isLoaded, fetchPlaceSuggestions, getPlaceById }}
        >
            {children}
            {apiKey && (
                <LoadMapsScript
                    libraries={["places"]}
                    setIsLoaded={setIsLoaded}
                    apiKey={apiKey}
                />
            )}
        </GoogleMapsPlacesContext.Provider>
    );
}

function LoadMapsScript({ libraries, setIsLoaded, apiKey }) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });
    useEffect(() => {
        setIsLoaded(isLoaded);
    }, [isLoaded]);
    return null;
}
